<template>
  <div class="box box--mapBlock">
    <div class="box__inner">
      <div class="mapBlock js-tabBox mapBlock--search">
        <div class="mapBlock__header">
          <div class="mapBlock__form">
            <form
              class="form form--filterSearch js-filterSearch"
              :class="[{'round' : this.type === 'round-trip'}, {'loading' : loading}]"
              data-href="/json/test.json"
              data-action="filterSearch"
              id="filterSearch"
              @submit.prevent="submitHandler"
            >
              <div class="mapBlock__form-top">
                <div class="mapBlock__form-left">
                  <label class="form__label form__label--radio">
                    <input class="form__radio js-changeWay" type="radio" v-model="type" value="one-way"/>
                    <span class="form__radio-box">One-way</span>
                    <span class="form__radio-icon" :style="{width: '12px'}">
                      <SvgIcon name="plane"/>
                    </span>
                  </label>
                  <label class="form__label form__label--radio">
                    <input class="form__radio js-changeWay" type="radio" v-model="type" value="round-trip"/>
                    <span class="form__radio-box">Round-trip</span>
                    <span class="form__radio-icon" :style="{width: '20px'}">
                      <SvgIcon name="double-plane"/>
                    </span>
                  </label>
                </div>
                <div class="mapBlock__form-center">
                  <label class="form__label form__label--checkbox">
                    <input class="form__checkbox" type="checkbox" v-model="booking" value="charter aircraft"/>
                    <span class="form__checkbox-icon"></span>
                    <span class="form__checkbox-box">Book charter aircraft</span>
                  </label>
                  <label class="form__label form__label--checkbox">
                    <input class="form__checkbox" type="checkbox" v-model="booking" value="charter seat"/>
                    <span class="form__checkbox-icon"></span>
                    <span class="form__checkbox-box">Book charter spaces</span>
                  </label>
                  <router-link to="manually-request" class="form__label form__label--checkbox">
                    <span class="form__checkbox-icon icon"></span>
                    <span class="form__checkbox-box text">Manual Request</span>
                  </router-link>
                  <!-- <label class="form__label form__label--checkbox">
                    <input class="form__checkbox" type="checkbox" v-model="booking" value="commercial seat"/>
                    <span class="form__checkbox-icon"></span>
                    <span class="form__checkbox-box">Book commercial seats</span>
                  </label> -->
                </div>
                <div class="mapBlock__form-right">
                  <div class="mapBlock__flight-info js-popup" @click="$modal.show('FlightRequestPopup')">
                    <SvgIcon name="controls"/>
                  </div>
                </div>
              </div>

              <div class="mapBlock__form-bottom darkPicker">
                <div class="form__labelGroup">
                  <label class="form__label form__label--text">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-typeahead"
                        type="text"
                        v-bind:value="from"
                        data-type="from"
                        @input="getLocationsFrom($event.target.value)"
                        @focus="showTypeheadMenu('from')"
                        @blur="hideTypeheadMenu"
                        :disabled="loading"
                        placeholder="Take off"
                      />
                      <div v-if="!this.from" class="form__caption">From</div>
                      <div
                        class="typeahead-menu typeahead-empty"
                        :class="{'typeahead-open' : fromOrTo === 'from'}"
                      >
                        <div class="typeahead-dataset typeahead-dataset-departureLocations">
                          <div
                            class="typeahead-suggestion typeahead-selectable"
                            data-type="select-from"
                            v-for="(item, index) of locationsListFrom" :key="index"
                            @click="selectLocation(item)"
                          >{{item.name}}</div>
                        </div>
                      </div>
                    </div>
                    <span
                      v-if="$v.from.$dirty && !$v.from.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--text">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-typeahead"
                        type="text"
                        v-bind:value="to"
                        data-type="to"
                        @input="getLocationsTo($event.target.value)"
                        @focus="showTypeheadMenu('to')"
                        @blur="hideTypeheadMenu"
                        :disabled="loading"
                        placeholder="Land"
                      />
                      <div v-if="!this.to" class="form__caption">To</div>
                      <div
                        class="typeahead-menu typeahead-empty"
                        :class="{'typeahead-open' : fromOrTo === 'to'}"
                      >
                        <div class="typeahead-dataset typeahead-dataset-departureLocations">
                          <div
                            class="typeahead-suggestion typeahead-selectable"
                            data-type="select-to"
                            v-for="(item, index) of locationsListTo" :key="index"
                            @click="selectLocation(item)"
                          >{{item.name}}</div>
                        </div>
                      </div>
                    </div>
                    <span
                      v-if="$v.to.$dirty && !$v.to.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
                  </label>
                  <label class="form__label form__label--date">
                    <div class="form__field-wrapper">
                      <!-- <a-date-picker
                        placeholder="select date"
                        :showToday="false"
                        :allowClear="false"
                        v-model="date"
                        format="MM/DD/YYYY"
                        :disabled="loading"
                        :getCalendarContainer="getPickerContainer"
                      /> -->
                      <date-picker v-if="type === 'one-way'" v-model="date"  format="MM/DD/YYYY" placeholder="SELECT DATE" :disabled="loading" style="margin-right: 13px;"></date-picker>
                      <date-picker v-else v-model="dateRange" format="MM/DD/YYYY" placeholder="SELECT DATE" :disabled="loading" range range-separator=" - "></date-picker>
                      <!-- <a-range-picker
                        v-else
                        placeholder="select date"
                        separator="-"
                        :allowClear="false"
                        v-model="dateRange"
                        format="MM/DD/YYYY"
                        :getCalendarContainer="getPickerContainer"
                      /> -->
                      <div class="form__caption">click to change</div>
                    </div>
                    <span
                      v-if="$v.selectedDate.$dirty && !$v.selectedDate.required"
                      class="form__field-invalid form__field-invalid--right-side"
                    >
                      This field is required
                    </span>
<!--                    <span-->
<!--                      v-if="$v.dateRange.$dirty && !$v.dateRange.required"-->
<!--                      class="form__field-invalid form__field-invalid&#45;&#45;right-side"-->
<!--                    >-->
<!--                      2222This field is required-->
<!--                    </span>-->
                  </label>
                  <label class="form__label form__label--number js-counter">
                    <div class="form__field-wrapper">
                      <div class="form__icon">
                        <SvgIcon name="users"/>
                      </div>
                      <input
                        class="form__field js-counter-input"
                        type="number"
                        v-model="count"
                        min="1"
                        max="99"
                        @blur="input"
                        :disabled="loading"
                      />
                      <div class="form__arrow">
                        <div class="form__arrow-plus js-counter-plus" @click="plus">
                          <SvgIcon name="up-arrow"/>
                        </div>
                        <div class="form__arrow-minus js-counter-minus" @click="minus">
                          <SvgIcon name="down-arrow"/>
                        </div>
                      </div>
                    </div>
                  </label>
                  <div class="form__label form__label--button">
                    <Spinner/>
                    <button class="form__button" :disabled="loading"><span class="text">Go</span></button>
                  </div>
                </div>
                <div class="form__labelGroup">
                  <label class="form__label form__label--checkbox">
                    <input class="form__checkbox coming_soon_checkbox" type="checkbox" v-model="radius" value="from"/>
                    <i class="form__checkbox-icon"></i>
                    <span class="form__checkbox-box">See all airports within a <span>50</span> mile radius</span>
                  </label>
                  <label class="form__label form__label--checkbox">
                    <input class="form__checkbox coming_soon_checkbox" type="checkbox" v-model="radius" value="to"/>
                    <i class="form__checkbox-icon"></i>
                    <span class="form__checkbox-box">See all airports within a <span>50</span> mile radius</span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="mapBlock__content">
         <div class="mapBlock__bg"></div>
          <div class="mapBlock__map" id="googleMap">
            <GmapMap
              :center="map.center"
              :zoom="map.zoom"
              :options="{maxZoom: 5, mapTypeControl: false, mapTypeId: 'hybrid', disableDefaultUI: true, labels: true}"
              style='width:100%;  height: 100%;'
              ref="gmap"
            >
              <GmapMarker
                v-if="markerFrom"
                :position="markerFrom !== null ? {lat: markerFrom.lat, lng: markerFrom.lng} : null"
                :animation="2"
                :icon="{ url: marker, size: {width: 35, height: 35, f: 'px', b: 'px'}, scaledSize: {width: 35, height: 35, f: 'px', b: 'px'}}"
              />
              <GmapMarker
                v-if="markerTo"
                :position="markerTo !== null ? {lat: markerTo.lat, lng: markerTo.lng} : null"
                :animation="2"
                :icon="{ url: marker, size: {width: 35, height: 35, f: 'px', b: 'px'}, scaledSize: {width: 35, height: 35, f: 'px', b: 'px'}}"
              />
              <GmapPolyline
                v-if="polylineShow"
                :path.sync="polylinePath"
                :options="{ strokeColor:'#000000',strokeOpacity: 1, strokeWeight: 2, geodesic: 'true'}"
              >
              </GmapPolyline>
            </GmapMap>
          </div>
          <div class="mapBlock__sidebar js-sidebar" :class="{'open' : sidebar}">
            <div class="mapBlock__sidebar-title js-sidebarTitle" @click="sidebar = !sidebar">
              <div class="mapBlock__sidebar-title-inner">
                <span class="text">Featured flights</span>
                <span class="icon">
                  <SvgIcon name="down-arrow"/>
                </span></div>
            </div>
            <div class="mapBlock__sidebar-content">
              <SearchList/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FlightRequestPopup @continue="flightRequestPopupDone"/>
    <PassengersPopup/>
    <InfoPopup />
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import marker from '@/assets/img/marker.svg'
  import './search.styl'
  import { Counter } from '@/js/plugins/counter'
  import SearchList from '@/views/main/mapBlock/SearchList'
  import { required } from 'vuelidate/lib/validators'
  import FlightRequestPopup from '@/components/common/modals/FlightRequestPopup'
  import PassengersPopup from '@/components/common/modals/PassengersPopup';
  import InfoPopup from '@/components/common/modals/InfoPopup';
  import moment from 'moment'
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import {loadStripe} from '@stripe/stripe-js';

  const counter = new Counter({
    min: 1,
    max: 99,
  })

  export default {
    name: 'mapBlock',
    data: () => ({
      type: 'one-way',
      booking: ['charter aircraft'],
      from: '',
      fromId: '',
      to: '',
      toId: '',
      fromOrTo: null,
      date: null,
      dateRange: null,
      selectedDate: null,
      count: 2,
      sidebar: false,
      radius: [],
      loading: false,
      timeout: null,
      map: {
        center: {lat: 40.6976637, lng: -74.119764},
        zoom: 5,
      },
      marker: marker,
      markerFrom: null,
      markerTo: null,
      polylineShow: false,
      polylinePath: [
        {lat: null, lng: null},
        {lat: null, lng: null },
      ],
      priceMax: null,
      aircraftType: null,
    }),
    validations: {
      from: { required },
      to: { required },
      selectedDate: {required},
    },
    computed: {
      locationsListFrom () {
        return this.$store.getters.shortListLocationsFrom(4)
      },
      locationsListTo () {
        return this.$store.getters.shortListLocationsTo(4)
      },
    },
    async created () {
      await this.$store.dispatch('getFeaturedFlights')
      const searchRequest = this.$store.getters.getBookingType
      if (searchRequest === 2) {
        this.booking = ['charter aircraft']
      } else if (searchRequest === 4) {
        this.booking = ['charter seat']
      } else if (searchRequest === 6) {
        this.booking = ['charter aircraft', 'charter seat']
      } else if (searchRequest === 10) {
        this.booking = ['charter aircraft', 'commercial seat']
      } else if (searchRequest === 12) {
        this.booking = ['charter seat', 'commercial seat']
      } else if (searchRequest === 14) {
        this.booking = ['charter aircraft', 'charter seat', 'commercial seat']
      } else {
        this.booking = ['charter aircraft']
      }
      document.querySelectorAll('.form__checkbox').forEach(box => {
        if (this.booking.length === 2) {
          this.booking.forEach(value => {
            if (box.value === value) {
              box.closest('.form__label--checkbox').classList.add('checked')
            }
          })
        } else {
          this.booking.forEach(value => {
            if (box.value === value) {
              box.closest('.form__label--checkbox').classList.add('checked')
            } else {
              box.closest('.form__label--checkbox').classList.remove('checked')
            }
          })
        }
      })
    },
    async mounted () {
      this.$root.fields.changeRadio('.form__radio')
      this.$root.fields.changeChecked('.form__checkbox')

      await this.showInfoAboutPayment()
    },
    methods: {
      async showInfoAboutPayment () {
        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')

        if (!clientSecret) {
          return
        }

        const stripe = await loadStripe(process.env.VUE_APP_PK_STRIPE)
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

        switch (paymentIntent?.status) {
          case 'succeeded':
          case 'processing':
            this.showInfoPopup('Payment Received', 'We will be in touch shortly to confirm your requested flight.')
            break
          case 'requires_payment_method':
            this.showInfoPopup('Error', 'Your payment was not successful, please try again.')
            break
          default:
            this.showInfoPopup('Error', 'Something went wrong.')
            break
        }
      },

      showInfoPopup (title, text) {
        this.$modal.show('InfoPopup', {
          title,
          text,
        })
      },

      setMarkerFrom (data) {
        setTimeout(() => {
          this.markerFrom = {
            lat: data.lat,
            lng: data.lng,
          }
          this.$refs.gmap.$mapPromise.then((map) => {
            map.panTo(this.markerFrom);
          })
        }, 1000)
      },
      setMarkerTo (data) {
        /* eslint-disable no-undef */
        var bounds = new google.maps.LatLngBounds();
        setTimeout(() => {
          this.markerTo = {
            lat: data.lat,
            lng: data.lng,
          }
          bounds.extend(this.markerFrom);
          bounds.extend(this.markerTo);
          this.$refs.gmap.$mapPromise.then((map) => {
            map.fitBounds(bounds);
          });
        }, 1000)
      },
      setBookingType () {
        let bookingTypesForUI

        if (this.booking.length === 1) {
          if (this.booking.includes('charter aircraft')) {
            bookingTypesForUI = 2
          } else if (this.booking.includes('charter seat')) {
            bookingTypesForUI = 4
          }
        } else if (this.booking.length === 2) {
          if (this.booking.includes('charter aircraft') && this.booking.includes('charter seat')) {
            bookingTypesForUI = 6
          } else if (this.booking.includes('charter aircraft') && this.booking.includes('commercial seat')) {
            bookingTypesForUI = 10
          } else if (this.booking.includes('charter seat') && this.booking.includes('commercial seat')) {
            bookingTypesForUI = 12
          }
        } else if (this.booking.length === 3) {
          if (this.booking.includes('charter aircraft') &&
            this.booking.includes('charter seat') &&
            this.booking.includes('commercial seat')) {
            bookingTypesForUI = 14
          }
        }

        return bookingTypesForUI
      },
      setSearchFor () {
        let searchFor

        if (this.booking.length === 1) {
          if (this.booking.includes('charter aircraft')) {
            searchFor = 'aircrafts'
          } else if (this.booking.includes('charter seat')) {
            searchFor = 'spaces'
          }
        } else if (this.booking.length === 2) {
          if (this.booking.includes('charter aircraft') && this.booking.includes('charter seat')) {
            searchFor = 'both'
          }
        }

        return searchFor
      },
      moment,
      async getLocationsFrom (value) {
        this.from = value
        const that = this

        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async function () {
          this.timeout = null

          const data = {
            query: that.from,
            type: 'from',
          }

          await that.$store.dispatch('searchLocations', data)
          that.fromOrTo = 'from'
          if (that.from === '' || !that.locationsListFrom.length) {
            // remove marker
            that.markerFrom = null
            that.fromOrTo = null
          }
        }, 500)
      },
      async getLocationsTo (value) {
        this.to = value
        const that = this

        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async function () {
          this.timeout = null

          const data = {
            query: that.to,
            type: 'to',
          }

          await that.$store.dispatch('searchLocations', data)
          that.fromOrTo = 'to'
          if (that.to === '' || !that.locationsListTo.length) {
            // remove marker
            that.markerTo = null
            that.fromOrTo = null
          }
        }, 500)
      },
      showTypeheadMenu (type) {
        this.locationsListFrom.length = 0
        this.locationsListTo.length = 0
        if (type === 'from' && this.locationsListFrom.length) {
          this.fromOrTo = 'from'
        } else if (type === 'to' && this.locationsListTo.length) {
          this.fromOrTo = 'to'
        } else {
          this.fromOrTo = null
        }
      },
      hideTypeheadMenu () {
        const that = this
        setTimeout(function () {
          that.fromOrTo = null
        }, 200)
      },
      selectLocation (item) {
        const that = this
        if (event.target.getAttribute('data-type') === 'select-from') {
          this.fromId = item.id
          this.from = item.name
          this.setMarkerFrom(item)
        } else {
          this.toId = item.id
          this.to = item.name
          this.setMarkerTo(item)
        }
        this.polylineShow = false
        setTimeout(function () {
          that.fromOrTo = null
        }, 200)
      },
      setPolyline () {
        if (this.markerFrom && this.markerTo) {
          this.polylinePath[0] = this.markerFrom
          this.polylinePath[1] = this.markerTo
          this.polylineShow = true
        } else {
          this.polylineShow = false
        }
      },
      getPickerContainer (trigger) {
        return document.querySelector('.darkPicker')
      },
      flightRequestPopupDone (data) {
        this.$modal.hide('FlightRequestPopup');
        if (data.priceMax === 0) {
          this.priceMax = null;
        } else {
          this.priceMax = data.priceMax;
        }
        if (data.aircraftType === 0) {
          this.aircraftType = null;
        } else {
          this.aircraftType = data.aircraftType;
        }
      },
      async submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        const bookingTypesForUI = this.setBookingType();
        if (!bookingTypesForUI) {
          this.$modal.show('InfoPopup', {
            title: 'Error',
            text: 'Choose booking charter type.',
          });
          return false
        }

        this.loading = true;
        const searchFor = this.setSearchFor()

        // let data, dataCharter
        let data, payload, directionTextForSearchResults, dateStringForSearchResults

        if (this.date !== null) {
          directionTextForSearchResults = 'One-way'

          const YYYY = this.date.getFullYear()

          const dayNum = this.date.getDate()
          const DD = dayNum < 10 ? '0' + dayNum : dayNum

          // Condition in ternary operator below will always evaluate to true and incorrectly add a '0' to the month number; pushed by 'Taras-Zakharko' of dotcode
          // const MM = (this.date.getMonth() + 1) ? '0' + (this.date.getMonth() + 1) : this.date.getMonth() + 1;

          const monthNum = this.date.getMonth() + 1
          const MM = monthNum < 10 ? '0' + monthNum : monthNum

          data = {
            arrivalDate: null,
            arrivalId: this.toId,
            bookingType: 2,
            departureDate: MM + '/' + DD + '/' + YYYY,
            departureId: this.fromId,
            passengers: this.count,
          }

          payload = {
            arrivalId: this.toId,
            arrivalName: this.to,
            bookingType: 2,
            departureDate: MM + '/' + DD + '/' + YYYY,
            departureId: this.fromId,
            departureName: this.from,
            direction: 2,
            pax: this.count,
            returnDate: null,
            searchFor,
            selectedBookingTypes: bookingTypesForUI,
            priceMax: this.priceMax,
            aircraftType: this.aircraftType,
          }

          const monthShort = this.date.toLocaleString('default', { month: 'short' })
          dateStringForSearchResults = `${monthShort} ${dayNum}, ${YYYY}`
        } else if (this.dateRange !== null) {
          directionTextForSearchResults = 'Round-trip'

          const departureDateObj = this.dateRange[0]
          const returnDateObj = this.dateRange[1]

          // DEPARTURE DATE

          const YYYYOne = departureDateObj.getFullYear()

          // Typo in 'then' clause. `this.datdateRangee` does not exist...; pushed by 'Taras-Zakharko' of dotcode
          // const DDOne = (this.dateRange[0].getDate() < 10) ? '0' + this.datdateRangee[0].getDate() : this.dateRange[0].getDate();
          const dayOneNum = departureDateObj.getDate()
          const DDOne = dayOneNum < 10 ? '0' + dayOneNum : dayOneNum

          // Condition in ternary operator below will always evaluate to true and incorrectly add a '0' to the month number; pushed by 'Taras-Zakharko' of dotcode
          // const MMOne = (this.dateRange[0].getMonth() + 1) ? '0' + (this.dateRange[0].getMonth() + 1) : this.dateRange[0].getMonth() + 1;
          const monthOneNum = departureDateObj.getMonth() + 1
          const MMOne = monthOneNum < 10 ? '0' + monthOneNum : monthOneNum

          // RETURN DATE

          const YYYYTwo = returnDateObj.getFullYear();

          const dayTwoNum = returnDateObj.getDate()
          const DDTwo = dayTwoNum < 10 ? '0' + dayTwoNum : dayTwoNum

          // Condition in ternary operator below will always evaluate to true and incorrectly add a '0' to the month number; pushed by 'Taras-Zakharko' of dotcode
          // const MMTwo = (this.dateRange[1].getMonth() + 1) ? '0' + (this.dateRange[1].getMonth() + 1) : this.dateRange[1].getMonth() + 1;
          const monthTwoNum = returnDateObj.getMonth() + 1
          const MMTwo = monthTwoNum < 10 ? '0' + monthTwoNum : monthTwoNum

          data = {
            arrivalDate: MMOne + '/' + DDOne + '/' + YYYYOne,
            arrivalId: this.toId,
            bookingType: 2,
            departureDate: MMTwo + '/' + DDTwo + '/' + YYYYTwo,
            departureId: this.fromId,
            passengers: this.count,
          }

          payload = {
            arrivalId: this.toId,
            arrivalName: this.to,
            bookingType: 2,
            departureDate: MMOne + '/' + DDOne + '/' + YYYYOne,
            departureId: this.fromId,
            departureName: this.from,
            direction: 1,
            pax: this.count,
            returnDate: MMTwo + '/' + DDTwo + '/' + YYYYTwo,
            searchFor,
            selectedBookingTypes: bookingTypesForUI,
            priceMax: this.priceMax,
            aircraftType: this.aircraftType,
          }

          const monthOneShort = departureDateObj.toLocaleString('default', { month: 'short' })
          const monthTwoShort = returnDateObj.toLocaleString('default', { month: 'short' })
          dateStringForSearchResults = `${monthOneShort} ${dayOneNum}, ${YYYYOne} - ${monthTwoShort} ${dayTwoNum}, ${YYYYTwo}`
        }

        // const historyRequest = await this.$store.dispatch('setSearchHistory', data)

        await this.$store.dispatch('setSearchHistory', data)

        // console.log(historyRequest);
        // if (!historyRequest) this.loading = false

        if (this.booking.length) {
          // const searchRequest = await this.$store.dispatch('setSearchRequest', dataCharter)
          // console.log('searchRequest', searchRequest)

          // const searchRequestCharterFlights = await this.$store.dispatch('setSearchRequestCharterFlights', dataCharter)
          // console.log('searchRequestCharterFlights', searchRequestCharterFlights)

          // console.log('payload', payload)
          // const isSuccessful = await this.$store.dispatch('setSearchResults', payload)
          const result = await this.$store.dispatch('setSearchResults', payload)
          console.log(result);
          if (result !== 'Error') {
            this.loading = false
            this.$router.push({
              name: 'search-results',
              params: {
                departureName: this.from,
                arrivalName: this.to,
                directionText: directionTextForSearchResults,
                dateText: dateStringForSearchResults,
                pax: this.count,
                bookingTypeText: searchFor === 'aircrafts'
                  ? 'Charter Aircrafts'
                  : searchFor === 'spaces'
                    ? 'Charter Spaces'
                    : 'Charter Aircrafts, Charter Spaces',
              },
            })
          } else {
            this.$modal.show('InfoPopup', {
              title: 'Error',
              text: 'Sorry, an error occurred while searching',
            })
          }

          // if (searchRequest || searchRequestCharterFlights) {
          // if (isSuccessful) {
          //   this.loading = false

          //   this.$router.push('search-results')
          // } else {
          //   // alert('search failed')
          //   this.loading = false

          //   // `!isSuccessful` just means no charters were found
          //   this.$router.push('search-results')
          // }

          setTimeout(() => {
            this.loading = false
            // console.log('loading false');
          }, 3000);
        }
      },

      minus () {
        this.count = counter.callMinus(this.count)
      },
      plus () {
        this.count = counter.callPlus(this.count)
      },
      input () {
        this.count = counter.callInput(this.count)
      },
    },
    watch: {
      type () {
        this.date = null
        this.dateRange = null
      },
      markerFrom () {
        this.setPolyline()
      },
      markerTo () {
        this.setPolyline()
      },
      radius () {
        this.$modal.show('ComingSoonPopup');
      },
      date () {
        this.selectedDate = this.date
      },
      dateRange () {
        this.selectedDate = this.dateRange
      },
      booking () {
        this.$store.dispatch('setSearchBookingType', this.setBookingType())
      },
    },
    components: {
      SvgIcon,
      Spinner,
      SearchList,
      FlightRequestPopup,
      PassengersPopup,
      InfoPopup,
      DatePicker,
    },
    metaInfo: {
      title: 'FLYJETS Map & Search',
      meta: [{
         name: 'og:title', content: 'FLYJETS',
      }],
    },
  }
</script>
