<template>
  <modal name="FlightRequestPopup" transition="pop-out" :adaptive="true" @opened="opened">
    <div class="formPopup" id="flightsSearchExtraFilters">
      <div class="formPopup__inner">
        <div class="formPopup__title">Additional flight request information</div>
        <div class="formPopup__form">
          <form
            class="form form--flightInfo js-ajaxForm"
            :class="[{'loading' : loading}, {'error' : priceError}]"
            data-href="/"
            data-action="flightInfo"
            id="flightInfoForm"
            @submit.prevent="submitHandler"
          >
            <div class="form__top">
              <div class="form__title">Choose aircraft type</div>
              <div class="form__labelGroup">
                <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="256"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Turboprop <span>(Max 6 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="2"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Very Light Jet <span>(Max 6 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="4"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Light Jet <span>(Max 8 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="8"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Midsize Jet <span>(Max 9 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="16"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Super Midsize Jet <span>(Max 16 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="32"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Heavy Jet <span>(Max 16 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="512"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Ultra-Long-Range Jet <span>(Max 16 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="64"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Executive Airliner <span>(Max 44 seats)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="128"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Helicopter <span>(Max 9 seats)</span></span>
                          </label>
              </div>
            </div>
            <div class="form__bottom">
              <div class="form__title">Max price</div>
              <div class="form__labelGroup">
                <div class="form__textGroup js-price-box">
                  <label class="form__label form__label--text price">
                    <div class="form__field-wrapper">
                      <input
                        class="form__field js-price-max"
                        type="text"
                        v-model="priceMax"
                        v-money="money"
                        placeholder="Max price"
                        autocomplete="off"
                        :disabled="loading"
                      />
                    </div>
                  </label>
                </div>
              </div>
              <div class="form__label form__label--button">
                <button class="form__button" type="submit" :disabled="loading">
                  <span class="text">Done</span>
                  <Spinner/>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <button type="button" class="modal__close" @click="$modal.hide('FlightRequestPopup')">
        <SvgIcon name="close-white"/>
      </button>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { VMoney } from 'v-money'

  export default {
    name: 'FlightRequestPopup',
    data: () => ({
      aircraftType: [],
      priceMax: '',
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        precision: 0,
        masked: true,
      },
      priceError: false,
      loading: false,
    }),
    directives: { money: VMoney },
    methods: {
      opened () {
        this.$root.fields.changeChecked('.form__checkbox')

        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')
          document.querySelector('body').appendChild(container)
        }
      },
      submitHandler () {
        const aircraftType = this.aircraftType.reduce((item, a) => (+item) + (+a), 0);
        const priceMax = Number(this.priceMax.replace('$ ', '').replace(',', ''));
        this.$emit('continue', {priceMax: priceMax, aircraftType: aircraftType});
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
