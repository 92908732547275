<template>
  <modal name="PassengersPopup" transition="pop-out" :adaptive="true" @before-open="beforeOpen" @closed="closed">
    <div class="passengersPopup">
      <div class="passengersPopup__wrapper">
        <span class="passengersPopup__close tm-closeButton" @click="$modal.hide('PassengersPopup')">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 20 19">
            <path fill="#fff"
                  d="M9.04355639,9.50291487 L0.190710575,17.9718741 C-0.0552365327,18.2071562 -0.0552365327,18.5883311 0.190710575,18.8236132 C0.313527872,18.9414037 0.474784171,19 0.635727958,19 C0.796984257,19 0.957928044,18.9414037 1.08074534,18.8236132 L10.0001563,10.2909753 L18.9195672,18.8236132 C19.042697,18.9414037 19.2036408,19 19.3645846,19 C19.5255283,19 19.6867846,18.9414037 19.8096019,18.8236132 C20.055549,18.5883311 20.055549,18.2071562 19.8096019,17.9718741 L10.9570686,9.50291487 L19.8155397,1.02797643 C20.0614868,0.792694344 20.0614868,0.411519429 19.8155397,0.176237343 C19.5695926,-0.0587457811 19.1711395,-0.0587457811 18.9255049,0.176237343 L10.0004688,8.71485441 L1.0744951,0.176536304 C0.82854799,-0.0584468204 0.430407438,-0.0584468204 0.18446033,0.176536304 C-0.0614867768,0.411818389 -0.0614867768,0.792993305 0.18446033,1.02827539 L9.04355639,9.50291487 Z"/>
          </svg>
        </span>
        <div class="passengersPopup__title">SPACES</div>
        <div class="passengersPopup__content">
          <form class="passengersPopup__form">
            <div class="passengersPopup__interface js-counter">
              <span class="passengersPopup__minus" @click="minus">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.4836 22.5671C16.6324 22.716 16.8184 22.7904 17.0231 22.7904C17.2277 22.7904 17.4138 22.716 17.5626 22.5671C17.8603 22.2694 17.8603 21.7857 17.5626 21.4881L8.07426 11.9997L17.5626 2.51131C17.8603 2.21364 17.8603 1.72991 17.5626 1.43224C17.265 1.13457 16.7812 1.13457 16.4836 1.43224L6.45566 11.4601C6.15798 11.7578 6.15798 12.2415 6.45566 12.5392L16.4836 22.5671Z"/>
                </svg>
              </span>
              <label class="passengersPopup__label">
                <input
                  class="passengersPopup__field"
                  type="number"
                  v-model="pax"
                  @blur="input"
                />
              </label>
              <span class="passengersPopup__plus" @click="plus">
                <svg viewBox="0 0 12 22" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.51644 21.5671C1.3676 21.716 1.18155 21.7904 0.976903 21.7904C0.772252 21.7904 0.586205 21.716 0.437368 21.5671C0.139694 21.2694 0.139694 20.7857 0.437368 20.4881L9.92574 10.9997L0.437367 1.51131C0.139693 1.21364 0.139693 0.729915 0.437367 0.43224C0.735041 0.134566 1.21876 0.134566 1.51644 0.43224L11.5443 10.4601C11.842 10.7578 11.842 11.2415 11.5443 11.5392L1.51644 21.5671Z"/>
                </svg>
              </span>
            </div>
            <button class="passengersPopup__button form__button" type="button" @click="bookFeaturedFlight">
              <span class="text">Confirm</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { Counter } from '@/js/plugins/counter'
  import './PassengersPopup.styl';

  export default {
    name: 'PassengersPopup',
    data: () => ({
      id: null,
      pax: 1,
      counter: null,
    }),
    methods: {
      beforeOpen (event) {
        this.id = event.params.id
        this.counter = new Counter({
          min: 1,
          max: event.params.availablePax,
        })
      },
      closed () {
        this.pax = 1
      },
      minus () {
        this.pax = this.counter.callMinus(this.pax)
      },
      plus () {
        this.pax = this.counter.callPlus(this.pax)
      },
      input () {
        this.pax = this.counter.callInput(this.pax)
      },
      bookFeaturedFlight () {
        this.$store.commit('setFeaturedFlightToBook', {
          id: this.id,
          pax: this.pax,
        })
        this.$router.push('/featured-flight')
      },
    },
  };
</script>
