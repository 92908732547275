<template>
  <vue-scroll class="mapBlock__list js-customScroll" :ops="ops">
    <div
      v-if="!searchList.length"
      class="mapBlock__list-empty"
    >
      Featured flights not found
    </div>
    <div
      class="mapBlock__list-item js-sidebarListItem"
      v-for="(item, index) in searchList"
      :key="index"
      @mouseenter="openContent"
      @mouseleave="closeContent"
    >
      <div class="mapBlock__list-item-inner">
        <ul class="mapBlock__list-item-row">
          <li class="mapBlock__list-item-cell">
            <span class="mapBlock__list-item-title">Available:</span>
            <span class="mapBlock__list-item-value">{{setDate(item.departureDate)}}</span>
          </li>
          <li class="mapBlock__list-item-cell">
            <span class="mapBlock__list-item-title">Aircraft:</span>
            <span class="mapBlock__list-item-value">{{item.aircraftModel}}</span>
          </li>
        </ul>

        <ul class="mapBlock__list-item-row">
          <li class="mapBlock__list-item-cell mapBlock__list-item-cell--from">
            <span class="mapBlock__list-item-title">{{item.departure.split(',')[0]}}</span>
          </li>
          <li class="mapBlock__list-item-cell mapBlock__list-item-cell--arrow">
            <span class="mapBlock__list-item-arrow">
              <SvgIcon name="long-left-arrow"/>
            </span>
          </li>
          <li class="mapBlock__list-item-cell mapBlock__list-item-cell--to">
            <span class="mapBlock__list-item-title">{{item.arrival.split(',')[0]}}</span>
          </li>
        </ul>

        <ul class="mapBlock__list-item-row">
          <li class="mapBlock__list-item-cell">
            <span class="mapBlock__list-item-title">spaces:</span>
            <span class="mapBlock__list-item-value">{{item.pax + '/' + item.aircraftPax}}</span>
          </li>
          <li class="mapBlock__list-item-cell mapBlock__list-item-cell--price">
            <span class="mapBlock__list-item-title">Per space:</span>
            <span class="mapBlock__list-item-value">${{item.totalPrice}}</span>
          </li>
        </ul>
      </div>
      <div class="mapBlock__list-item-back js-sidebarListItemDetail">
        <div class="mapBlock__list-item-button-wr">
          <button
            class="mapBlock__list-item-button"
            @click="$modal.show('PassengersPopup', {id: item.emptyLegId, availablePax: item.aircraftPax - item.pax})"
          >Book</button>
        </div>
        <div class="mapBlock__list-item-info">
          <div class="mapBlock__list-item-info-left">
            <ul class="mapBlock__list-item-info-list">
              <li class="mapBlock__list-item-info-list-item">
                <span class="mapBlock__list-item-info-list-title">category:&ensp;</span>
                <span class="mapBlock__list-item-info-list-value">{{item.aircraftType}}</span>
              </li>
              <li class="mapBlock__list-item-info-list-item">
                <span class="mapBlock__list-item-info-list-title">speed:&ensp;</span>
                <span class="mapBlock__list-item-info-list-value">{{item.aircraftSpeed}} M/H</span>
              </li>
              <li class="mapBlock__list-item-info-list-item">
                <span class="mapBlock__list-item-info-list-title">range:&ensp;</span>
                <span class="mapBlock__list-item-info-list-value">{{item.aircraftRange}} M</span>
              </li>
            </ul>
          </div>
          <div
            class="mapBlock__list-item-info-right"
            :style="{backgroundImage: `url(${item.defaultImageUrl})`}"
          ></div>
        </div>
      </div>
    </div>
  </vue-scroll>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import {months} from '@/js/utils/date'

  export default {
    name: 'SearchList',
    data: () => ({
      image: require('@/assets/img/map/1.jpg'),
      count: 1,
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      searchList () {
        return this.$store.state.search.featuredFlights
      },
    },
    methods: {
      openContent (event) {
        const item = event.currentTarget.querySelector('.js-sidebarListItemDetail')
        this.$root.animations.slideDown(item)
      },
      closeContent (event) {
        const item = event.currentTarget.querySelector('.js-sidebarListItemDetail')
        this.$root.animations.slideUp(item)
      },
      setDate (date) {
        const result = new Date(date)
        result.setDate(result.getDate())
        return result.getDate() + ' ' + months[result.getMonth()]
      },
    },
    components: {
      SvgIcon,
    },
  }
</script>
